var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitResponse.apply(null, arguments)}}},[_c('p',{staticClass:"mb-2"},[_vm._v(" Complete the given flow chart illustrating the steps in the "),_c('i',[_vm._v("Extraction")]),_vm._v(" lab - "),_c('i',[_vm._v("Parts 1 and 2.")])]),_c('p',{staticClass:"mb-2"},[_c('b',[_vm._v("Step 1.")]),_vm._v(" "),_c('i',[_vm._v("Separation of the neutral compound and the unknown acid.")])]),_c('p',[_c('v-select',{staticClass:"my-1",staticStyle:{"display":"inline-block","width":"12em"},attrs:{"outlined":"","items":_vm.options,"item-text":"text","item-value":"value"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input1),callback:function ($$v) {_vm.$set(_vm.inputs, "input1", $$v)},expression:"inputs.input1"}}),_vm._v(" 10 mL "),_c('v-select',{staticClass:"my-1",staticStyle:{"display":"inline-block","width":"12em"},attrs:{"outlined":"","items":_vm.options,"item-text":"text","item-value":"value"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input2),callback:function ($$v) {_vm.$set(_vm.inputs, "input2", $$v)},expression:"inputs.input2"}})],1),_c('p',[_vm._v("(image placeholder)")]),_vm._v(" swirl -> "),_c('p',[_vm._v("(image placeholder)")]),_c('p',[_vm._v(" + "),_c('v-select',{staticClass:"my-1",staticStyle:{"display":"inline-block","width":"12em"},attrs:{"outlined":"","items":_vm.options,"item-text":"text","item-value":"value"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input3),callback:function ($$v) {_vm.$set(_vm.inputs, "input3", $$v)},expression:"inputs.input3"}}),_vm._v(" -> ")],1),_c('p',[_vm._v(" Shake and vent the funnel every 30 seconds until no further "),_c('v-select',{staticClass:"my-1",staticStyle:{"display":"inline-block","width":"12em"},attrs:{"outlined":"","items":_vm.options,"item-text":"text","item-value":"value"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input4),callback:function ($$v) {_vm.$set(_vm.inputs, "input4", $$v)},expression:"inputs.input4"}}),_vm._v(" gas build-up is observed. Allow the layers to separate. Drain layer into a clean flask. ")],1),_c('p',[_vm._v(" -> Wash the top layer with "),_c('v-select',{staticClass:"my-1",staticStyle:{"display":"inline-block","width":"12em"},attrs:{"outlined":"","items":_vm.options,"item-text":"text","item-value":"value"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input5),callback:function ($$v) {_vm.$set(_vm.inputs, "input5", $$v)},expression:"inputs.input5"}})],1),_c('p',[_vm._v(" . Drain the resulting "),_c('v-select',{staticClass:"my-1",staticStyle:{"display":"inline-block","width":"12em"},attrs:{"outlined":"","items":_vm.options,"item-text":"text","item-value":"value"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input6),callback:function ($$v) {_vm.$set(_vm.inputs, "input6", $$v)},expression:"inputs.input6"}}),_vm._v(" layer into the same flask. ")],1),_c('p',[_vm._v("-> Label the flask and set it aside.")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }